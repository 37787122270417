import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@app/app.config';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    standalone: false,
})
export class MaintenanceComponent implements OnInit {
    @ViewChild('hamburger') hamburger!: ElementRef<HTMLElement>;
    @ViewChild('mobileMenu') mobileMenu!: ElementRef<HTMLElement>;

    isMenuOpen: boolean = false;

    navigationLinks = [
        {
            url: 'https://cloud4log.de/#wasistcloud4log',
            text: 'Was ist Cloud4Log?',
        },
        { url: 'https://cloud4log.de/#wirsinddabei', text: 'Wir sind dabei' },
        {
            url: 'https://cloud4log.de/#wiefunktioniertcloud4log',
            text: 'Wie funktioniert Cloud4Log',
        },
        { url: 'https://cloud4log.de/#kontakt', text: 'Kontakt' },
        { url: 'https://cloud4log.de/#support', text: 'Support' },
    ];

    constructor(
        private appConfig: AppConfig,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.check();
    }

    check(): void {
        if (this.appConfig.maintenance) {
            setTimeout(async () => {
                await this.appConfig.load();
                this.check();
            }, 5000);
        } else {
            this.router.navigate(['/']);
        }
    }

    toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
        if (this.hamburger) {
            this.hamburger.nativeElement.classList.toggle('active');
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        const clickedElement = event.target as HTMLElement;

        if (
            this.isMenuOpen &&
            this.hamburger &&
            this.mobileMenu &&
            !this.hamburger.nativeElement.contains(clickedElement) &&
            !this.mobileMenu.nativeElement.contains(clickedElement)
        ) {
            this.isMenuOpen = false;
            this.hamburger.nativeElement.classList.remove('active');
        }
    }
}
