<div class="wrapper">
    <nav>
        <div class="logo">
            <img
                src="assets/images/cloud4log_log.png"
                alt="Company Logo" />
        </div>
        <div class="nav-links">
            <a href="https://cloud4log.de/#wasistcloud4log"
                >Was ist Cloud4Log?</a
            >
            <a href="https://cloud4log.de/#wirsinddabei">Wir sind dabei</a>
            <a href="https://cloud4log.de/#wiefunktioniertcloud4log"
                >Wie funktioniert Cloud4Log</a
            >
            <a href="https://cloud4log.de/#kontakt">Kontakt</a>
            <a href="https://cloud4log.de/#support">Support</a>
        </div>
        <button
            #hamburger
            class="hamburger"
            (click)="toggleMenu()">
            <span></span>
            <span></span>
            <span></span>
        </button>
    </nav>

    <div class="maintenance-notice">
        <span
            >🔧 Aufgrund von Wartungsarbeiten ist der Cloud4Log-Service
            vorübergehend nicht erreichbar. Wir arbeiten im Hintergrund daran,
            Cloud4Log für Sie zu optimieren. Bitte haben Sie etwas Geduld,
            während wir notwendige Wartungsarbeiten durchführen. Cloud4Log wird
            Ihnen in wenigen Minuten wieder zur Verfügung stehen.</span
        >
        <p>Vielen Dank für Ihr Verständnis!</p>

        <span
            >🔧 Due to maintenance, Cloud4Log is temporarily unavailable. We are
            working in the background to optimize Cloud4Log for you. Please be
            patient while we carry out the necessary maintenance. Cloud4Log will
            be available again in a few minutes.</span
        >
        <p>Thank you for your understanding!</p>
    </div>

    <div class="hero-section">
        <div class="container">
            <!--  <div class="content">                
          <div class="slide active" data-slide="1">
            <h1>
              <span>Der digitale Lieferschein</span>
            </h1>
            <p>
              Gemeinsam wollen wir die Logistik einfacher machen. Cloud4Log ist
              ein Kooperationsprojekt von GS1 Germany und der Bundesvereinigung
              Logistik.
            </p>
          </div>

       
          <div class="slide" data-slide="2">
            <h1>
              <span>Einfache digitale Verwaltung</span>
            </h1>
            <p>
              Mit Cloud4Log digitalisieren Sie Ihre Lieferscheine und optimieren
              Ihre Logistikprozesse. Sparen Sie Zeit und Ressourcen.
            </p>
          </div>

         
          <div class="slide" data-slide="3">
            <h1>
              <span>Sichere Cloud Lösung</span>
            </h1>
            <p>
              Ihre Daten sind bei uns sicher. Wir setzen auf modernste
              Technologien und höchste Sicherheitsstandards.
            </p>
          </div>

          <div class="slider-controls">
            <div class="dot active" data-slide="1"></div>
            <div class="dot" data-slide="2"></div>
            <div class="dot" data-slide="3"></div>
          </div>
          <a href="https://cloud4log.de/" class="btn">Mehr erfahren</a>
        </div>-->

            <div class="illustration">
                <div class="gear gear1"></div>
                <div class="gear gear2"></div>
                <div class="gear gear3"></div>
                <div class="phone">
                    <img
                        src="assets/images/maintenance.png"
                        alt="System Maintenance"
                        class="phone-image" />
                </div>
            </div>
        </div>
    </div>

    <!-- Mobile Menu -->
    <div
        class="mobile-menu"
        #mobileMenu
        [class.active]="isMenuOpen">
        <a href="https://cloud4log.de/#wasistcloud4log">Was ist Cloud4Log?</a>
        <a href="https://cloud4log.de/#wirsinddabei">Wir sind dabei</a>
        <a href="https://cloud4log.de/#wiefunktioniertcloud4log"
            >Wie funktioniert Cloud4Log</a
        >
        <a href="https://cloud4log.de/#kontakt">Kontakt</a>
        <a href="https://cloud4log.de/#support">Support</a>
    </div>
</div>
